// 
import state from './moduleNotifState.js'
import mutations from './modulesNotifMutations.js'
import actions from './moduleNotifActions.js'
import getters from './moduleNotifGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
