
// eslint-disable-next-line no-unused-vars

import axios from 'axios'

const baseURL = 'https://api.haio.ir/v1'
// const baseURL = 'http://api.haio.local/v1'
// const baseURL = 'http://127.0.0.1:8000/v1'

// const accessToken = localStorage.getItem('accessToken')

const instance = axios.create({
  baseURL
  // headers : {
  //   'Authorization' : `Bearer ${accessToken}`
  // }
})


export default instance
