import state from './moduleHaioCloudState.js'
import mutations from './moduleHaioCloudMutations.js'
import actions from './moduleHaioCloudActions.js'
import getters from './moduleHaioCloudGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
