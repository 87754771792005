import jwt from '../../http/authConfig'


import router from '@/router'


export default {
  // JWT
  loginJWT ({commit}, payload) {
    return new Promise((resolve, reject) => {
      // console.log(payload)
      jwt.login(payload.userDetails).then((res) => {
        if (res.data.status) {
          // SET ACCESS_TOKEN
          jwt.setToken(res.data.params.data.access_token)

          // SET REFRESH_TOKEN
          jwt.setRefreshToken(res.data.params.data.refresh_token)
          commit('IS_AUTH')
          resolve(res)
        }
      }).catch((err) => {
        reject(err)
      })

    })
  },

  //register

  registerUserJWT (_, payload) {

    const { first_name, last_name, national_id, email, mobile, password, confirmPassword } = payload

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.register(first_name, last_name, national_id, email, mobile, password)
        .then(response => {

          // Redirect User

          //user is login
          // commit('USER_LOGIN_CONTROL', {value :true})

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          // commit('UPDATE_USER_INFO', response.data.userData, {root: true})
          resolve(response)

        })
        .catch(error => { reject(error) })
    })
  },

  logout () {

  },


  fetchUserData ({commit}, payload) {
    return new Promise((resolve, reject) => {
      jwt.getUserInfo(payload).then((response) => {
        const userData = response.data.params
        commit('UPDATE_USER_INFO', userData, {root: true})
        resolve()
      }).catch(() => reject())
    })
  },

  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => {
        const p = response.data.params.data
        // console.log(p)
        localStorage.setItem('TT', Date(Date.now() + (30 * 60 * 1000)))
        localStorage.setItem('accessToken', p.access_token)
        localStorage.setItem('refreshToken', p.refresh_token)

        resolve(p.access_token)
      })
        .catch(() => {
          router.push({path : '/pages/login'})
        // localStorage.clear()

        })
    })
    // location.reload()
  },
  async backToLogin () {
    await router.push({path : '/pages/login'})
    await localStorage.clear()


  }
}
