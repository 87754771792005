

import instance from '@/axios.js'
import useJwt from '../../http/authConfig'

export default {


  IS_AUTH (state) {
    // console.log(!!(localStorage.getItem('userInfo') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)))
    state.isUserLoggedIn.isAuthenticated = !!(localStorage.getItem('userInfo') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName))
  },
  
  USER_LOGIN_CONTROL (state, paylod) {
    if (localStorage.getItem('accessToken')) {

      state.isUserLoggedIn.isAuthenticated = paylod.value
    }

  },

  SET_BEARER (state, accessToken) {

    // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    instance.interceptors.request.use(config => {
      config.headers.Authorization =  `Bearer ${accessToken}`
      config.headers['Content-Type'] =  'application/json'
      return config  
    }, error => Promise.reject(error))
  }
}
