import Vue from 'vue'
import Router from 'vue-router'
// eslint-disable-next-line no-unused-vars
import store from './store/store'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/pages/login'
        },
        {
          path: '/instance/list',
          name: 'dashboard',
          component: () => import('./views/apps/haiocloud/viracloud/InstanceList.vue'),
          meta: {
            rule: 'User'
          }
        },

        // {
        //   path: '/instance/auction/list',
        //   name: 'dashboard',
        //   component: () => import('./views/apps/haiocloud/viracloud/AuctionList.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        {
          path: '/mainpage',
          name: 'mainpage',
          component: () => import('./views/MainPage.vue'),
          meta: {
            rule: 'User'
          }
        },

        {
          path: '/docker/list',
          name: 'dashboard',
          component: () => import('./views/apps/docker/DockerList.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/docker/info/:id',
          name: 'vmDetails',
          component: () => import(
              './views/apps/docker/DockerDetails.vue'
            ),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/docker/create',
          name: 'dashboard',
          component: () => import('./views/apps/docker/DockerCreate.vue'),
          meta: {
            rule: 'User'
          }
        },
        // =============================================================================
        // Application Routes
        // =============================================================================
        // {
        //   path: '/apps/email/',
        //   redirect: '/apps/email/inbox',
        //   name : 'email',
        //   component: () => import('./views/apps/email/Email.vue'),
        //   meta: {
        //     rule: 'User',
        //     no_scroll: false

        //   }
        // },
        // {
        //   path: '/apps/email/:filter',
        //   component: () => import('./views/apps/email/Email.vue'),
        //   meta: {
        //     rule: 'User',
        //     no_scroll: false

        //   }
        // },

        // {
        //   path: '/apps/haioemail/',
        //   name: 'haioemail',
        //   component: () => import('./views/apps/haioEmail/domain/Email.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'دامنه ها', active: true }
        //     ],
        //     // pageTitle: 'سرویس ایمیل',
        //     no_scroll: true
        //   }
        // },
        // {
        //   path: '/apps/haioemail/chart',
        //   name: 'emilachart',
        //   component: () => import('./views/apps/haioEmail/chart/Chartjs.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'Haiomail', active: true }
        //     ],
        //     // pageTitle: 'سرویس ایمیل',
        //     no_scroll: true
        //   }
        // },
        // {
        //   path: '/apps/haioemail/instruction',
        //   name: 'instrution',
        //   component: () => import('./views/apps/haioEmail/instruction/InsPage.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/haioemail/:domain',
        //   name: 'domainList',
        //   component: () => import('./views/apps/haioEmail/domain/TableEmail.vue'),
        //   props: true,
        //   meta: {
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'دامنه ها', url: '/apps/haioemail' },
        //       { title: 'مدیریت ایمیل های دامنه', active: true }
        //     ],
        //     rule: 'User',
        //     no_scroll: true
        //   }
        // },

        {
          path: '/apps/ticket/',
          name: 'ticket',
          // component: () => import('./views/apps/tiket/Tiket.vue'),
          component: () => import('./views/apps/support/Ticket.vue'),
          meta: {
            rule: 'User'
          }
        },

        {
          path: '/apps/tickets/',
          name: 'support',
          component: () => import('./views/apps/tiket/Tiket.vue'),
          meta: {
            rule: 'User',

            no_scroll: true
          }
        },
        {
          path: '/apps/tickets/:id',
          name: 'ticket-detail',
          component: () => import('./views/apps/support/TicketChat.vue'),
          meta: {
            rule: 'User',

            no_scroll: true
          }
        },
        // {
        //   path: '/apps/haioServer/',
        //   name: 'haioServer',
        //   component: () => import('./views/apps/haiocloud/viracloud/CreateServer.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/server-location/',
        //   name: 'createServer',
        //   component: () => import('./views/apps/server-location/index'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/viracloud/',
        //   name: 'viracloud',
        //   component: () => import('./views/apps/haiocloud/viracloud/ViraCloud.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/viracloud/simple',
        //   name: 'virasimple',
        //   component: () => import('./views/apps/haiocloud/viracloud/ViraCloudSimple.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        {
          path: '/instance/create',
          name: 'viraWizard',
          component: () => import('./views/apps/haiocloud/viracloud/ViraCloudWizard.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/instance/auction/create',
          name: 'viraWizard',
          component: () => import('./views/apps/haiocloud/viracloud/ViraCloudWizardAuction.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/vdi/create',
          name: 'vdiWizard',
          component: () => import('./views/apps/haiocloud/viracloud/ViraCloudVdiWizard.vue'),
          meta: {
            rule: 'User'
          }
        },

        {
          path: '/vdi/list',
          name: 'dashboard',
          component: () => import('./views/apps/haiocloud/viracloud/DesktopList.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/vdi/details/:id',
          name: 'vmDetails',
          component: () => import(
            './views/apps/haiocloud/viracloud/vdidetails/VdiDetails.vue'
            ),
          meta: {
            rule: 'User'
          }
        },


        {
          path: '/anti/sanction/create',
          name: 'AntiSanctionWizard',
          component: () => import('./views/apps/haiocloud/viracloud/AntiSanctionWizard.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/anti/sanction',
          name: 'AntiSanction',
          component: () => import('./views/apps/haiocloud/viracloud/AntiSanctionList.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/anti/sanction/details/:id',
          name: 'vmDetails',
          component: () => import(
            './views/apps/haiocloud/viracloud/antiSanctionDetails/AntiSanctionDetails.vue'
            ),
          meta: {
            rule: 'User'
          }
        },

        // {
        //   path: '/new/server/:serverType?/:location?',
        //   name: 'viraWizard',
        //   component: () => import('./views/apps/haiocloud/viracloud/ViraCloudWizard.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },

        {
          path: '/apps/viracloud/:id',
          name: 'vmDetails',
          component: () => import(
              './views/apps/haiocloud/viracloud/viradetails/ViraDetails.vue'
            ),
          meta: {
            rule: 'User'
          }
        },
        // {
        //   path: '/apps/firewall',
        //   name: 'firewall',
        //   component: () => import(
        //       './views/apps/haiocloud/viracloud/firewall/FirewallGroup.vue'
        //     ),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/firewall/:id',
        //   name: 'firewallRules',
        //   component: () => import(
        //       './views/apps/haiocloud/viracloud/firewall/FirewallRules.vue'
        //     ),
        //   meta: {
        //     rule: 'User'
        //   }
        // },

        {
          path: '/apps/viraVpc',
          name: 'createVpc',
          component: () => import('./views/apps/haiocloud/viracloud/vpc/VpcPage.vue'),
          meta: {
            rule: 'User'
          }
        },
        // {
        //   path: '/apps/viraVpc/tree/:id',
        //   name: 'treeChart',
        //   component: () => import('./views/apps/haiocloud/viracloud/vpc/TreeChart.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/virtualrouter',
        //   name: 'vrouter',
        //   component: () => import('./views/apps/haiocloud/viracloud/virtualRouter/VirtualRouter.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/viraplus',
        //   name: 'createViraplus',
        //   component: () => import('./views/apps/haiocloud/newvira/CreateViraPlus.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/vmlists',
        //   name: 'virapluslist',
        //   component: () => import('./views/apps/haiocloud/newvira/ViraPlusList.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/vmdetail/:id',
        //   name: 'vm-detail',
        //   component: () => import('./views/apps/haiocloud/newvira/details/VmDetailPage.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },

        {
          path: '/apps/addkey/',
          name: 'AddKey20.',
          component: () => import('./views/apps/ssh/KeyManagement.vue'),
          meta: {
            rule: 'User'
          }
        },

        // {
        //   path: '/apps/monitoring/',
        //   name: 'monitoring',
        //   component: () => import('./views/apps/monitoring/Monitoring.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'دیدبان', active: true }
        //     ]
        //   }
        // },

        // {
        //   path: '/apps/monitoring/events/:id',
        //   name: 'monitoringEvents',
        //   component: () => import('./views/apps/monitoring/MonitoringLog.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'دیدبان', url: '/apps/monitoring' },
        //       { title: 'رویداد', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/monitoring/setting',
        //   name: 'monitoringChannel',
        //   component: () => import('./views/apps/monitoring/ChannelPage.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'دیدبان', url: '/apps/monitoring' },
        //       { title: 'تنظیمات', active: true }
        //     ]
        //   }
        // },

        // {
        //   path: '/apps/monitoring/ssl',
        //   name: 'sslChecker',
        //   component: () => import('./views/apps/monitoring/SslReport.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'دیدبان', url: '/apps/monitoring' },
        //       { title: 'گواهی ssl', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/calculator',
        //   name: 'Calculator',
        //   component: () => import('./views/apps/calculator/Calculator.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'ماشین حساب', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/salescooperation',
        //   name: 'salesc',
        //   component: () => import('./views/pages/user-settings/SalesCooperation/index.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: '  همکاری در فروش', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/rewards',
        //   name: 'reward',
        //   component: () => import('./views/components/rewards/Rewards.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'هایو', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/rewardList',
        //   name: 'reward',
        //   component: () => import('./views/components/rewards/RewardList'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'هایو', active: true }
        //     ]
        //   }
        // },
        // // DNS roues
        // {
        //   path: '/apps/DNS',
        //   name: 'dns',
        //   component: () => import('./views/apps/DNS/DNS.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'DNS', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/DNS/add',
        //   name: 'dns-add',
        //   component: () => import('./views/apps/DNS/AddDns.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'DNS', url: '/apps/DNS' },
        //       {
        //         title: 'اضافه کردن DNS جدید',
        //         url: '/apps/DNS/add',
        //         active: true
        //       }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/DNS/info/:id',
        //   name: 'dns-info',
        //   component: () => import('./views/apps/DNS/DNSInfo.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       {
        //         title: 'Home',
        //         url: '/dashboard'
        //       },
        //       {
        //         title: 'DNS',
        //         url: '/apps/DNS'
        //       },
        //       {
        //         title: '  DNS لیست ',
        //         url: '/apps/DNS',
        //         active: true
        //       }
        //     ]
        //   }
        // },
        //end DNS route
        // {
        //   path: '/apps/cloudlist/',
        //   name: 'viracloudlist',
        //   component: () => import(
        //       './views/apps/haiocloud/viracloud/viradetails/ViraCloudList.vue'
        //     ),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'ویرا ابر', url: '/apps/viracloud' },
        //       { title: 'لیست ویرا ابرها', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/cloudList/details',
        //   name: 'details',
        //   component: () => import(
        //       './views/apps/haiocloud/viracloud/viradetails/ViraDetails.vue'
        //     ),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'ویرا ابر', url: '/apps/viracloud' },
        //       { title: 'لیست ویرا ابرها', url: '/apps/cloudlist' },
        //       { title: 'مشخصات سرویس', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/haioabr/',
        //   name: 'haioabr',
        //   component: () => import('./views/apps/haiocloud/haioabr/HaioCloud.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/server/select/',
        //   name: 'haioabr',
        //   component: () => import('./views/apps/server/ServerSelect.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/haiocloudlist/',
        //   name: 'haiocloudlist',
        //   component: () => import(
        //       './views/apps/haiocloud/haioabr/haioabedetails/HaioCloudList.vue'
        //     ),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'هایو ابر', url: '/apps/haioabr' },
        //       { title: 'لیست هایو ابر ها', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/haiocloudlist/details/:id',
        //   name: 'cloudDetails',
        //   component: () => import(
        //       './views/apps/haiocloud/haioabr/haioabedetails/HaioCloudDetails.vue'
        //     ),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'هایو ابر', url: '/apps/haioabr' },
        //       { title: 'لیست هایو ابر ها', url: '/apps/haiocloudlist/' },
        //       { title: 'اطلاعات سرویس', active: true }
        //     ]
        //   }
        // },
        /// notification
        {
          path: '/notification/list',
          name: 'notification/list',
          component: () => import('../src/views/pages/NotificationList/index.vue'),
          meta: {
            rule: 'User',
            breadcrumb: [
              {
                title: 'Home',
                url: '/dashboard'
              },
              {
                title: 'show',
                url: '/dashboard',
                active: true
              }
            ]
          }
        },
        {
          path: '/notification/show/:id',
          name: 'notification/show',
          component: () => import('../src/views/components/Notification/ShowNotification.vue'),
          meta: {
            rule: 'User',
            breadcrumb: [
              {
                title: 'Home',
                url: '/dashboard'
              },
              {
                title: 'show',
                url: '/dashboard',
                active: true
              }
            ]
          }
        },
        ///end notification

        // Start Host Routes
        // {
        //   path: '/apps/create/host',
        //   name: 'Create-Host',
        //   component: () => import('./views/apps/host/HostPage.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/list/host',
        //   name: 'List-Host',
        //   component: () => import('./views/apps/host/HostList.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/apps/list/host/:id',
        //   name: 'Host-Details',
        //   component: () => import('./views/apps/host/hostDetails/HostDetails.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        {
          path: '/new/service',
          name: 'add-services',
          component: () => import('./views/apps/haioServices/Services.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/services/list',
          name: 'list-services',
          component: () => import('./views/apps/haioServices/ServiceList.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/service/details/:id',
          name: 'details-services',
          component: () => import('./views/apps/haioServices/ServiceDetails.vue'),
          meta: {
            rule: 'User'
          }
        },

        // {
        //   path: '/apps/containo',
        //   name : 'Countino',
        //   component: () => import('./views/apps/countino/Containo.vue'),
        //   meta: {
        //     rule: 'User'

        //   }
        // },
        // {
        //   path: '/apps/containoList',
        //   name : 'CountinoList',
        //   component: () => import('./views/apps/countino/ContainoList.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'Home', url: '/dashboard' },
        //       { title: 'Containo', url:'/apps/containo'},
        //       { title: 'Contanio List', active:true }

        //     ]

        //   }
        // },
        // {
        //   path: '/apps/containo-details/',
        //   name : 'CountinoDetails',
        //   component: () => import('./views/apps/countino/ContainoDetail.vue'),
        //   meta: {
        //     rule: 'User',
        //     breadcrumb: [
        //       { title: 'داشبورد', url: '/dashboard' },
        //       { title: 'کانتینو', url:'/apps/containo'},
        //       { title: 'لیست کانتینو', url:'/apps/containoList' },
        //       { title: 'اطلاعات', active : true}
        //     ]

        //   }
        // },

        /*
                  Below route is for demo purpose
                  You can use this route in your app
                    {
                        path: '/apps/eCommerce/item/',
                        name: 'ecommerce-item-detail-view',
                        redirect: '/apps/eCommerce/shop',
                    }
                */

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: '/pages/profile',
          name: 'page-profile',
          component: () => import('@/views/pages/Profile.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pages' },
              { title: 'Profile', active: true }
            ],
            pageTitle: 'Profile',
            rule: 'User'
          }
        },
        // {
        //   path: '/pages/comingsoon',
        //   name: 'comming-soon',
        //   component: () => import('@/views/pages/ComingSoon.vue'),
        //   meta: {

        //     rule: 'User'
        //   }
        // },
        {
          path: '/pages/user-settings',
          name: 'page-user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            // breadcrumb: [
            //   { title: 'Home', url: '/' },
            //   { title: 'Pages' },
            //   { title: 'User Settings', active: true }
            // ],
            pageTitle: 'تنظیمات کاربری',
            rule: 'User',
            no_scroll: true
          }
        },
        {
          path: '/apps/paymentManage',
          name: 'page-paymentManage',
          component: () => import('@/views/apps/invoice/paymentManage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: ' بخش مالی', active: true }
            ],
            rule: 'User'
          }
        },
        {
          path: '/apps/pay',
          name: 'IncrementCharge',
          component: () => import('./views/apps/pay/index.vue'),
          meta: {
            rule: 'User',
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'افزایش شارژ', active: true }
            ]
          }
        },
        {
          path: '/apps/payment',
          name: 'page-payment',
          component: () => import('@/views/apps/invoice/PaymentPage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'پرداخت ها', active: true }
            ],
            rule: 'User'
          }
        },
        {
          path: '/apps/manual',
          name: 'اعلانی وجود ندارد ! page-manual',
          component: () => import('@/views/apps/invoice/Manual.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'فاکتور', active: true }
            ],
            rule: 'User'
          }
        },

        {
          path: '/apps/transaction',
          name: 'page-transacitoin',
          component: () => import('@/views/apps/invoice/TransactionPage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'تراکنش ها', active: true }
            ],
            rule: 'User'
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'User'
          }
        },
        // {
        //   path: '/pages/verifyaccount',
        //   name: 'verify-account',
        //   component: () => import('@/views/pages/VerifyEmailMobile.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/pages/register',
        //   name: 'page-register',
        //   component: () => import('@/views/pages/register/Register.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        // {
        //   path: '/pages/forgot-password',
        //   name: 'page-forgot-password',
        //   component: () => import('@/views/pages/ForgotPassword.vue'),
        //   meta: {
        //     rule: 'User'
        //   }
        // },
        {
          path: '/apps/manual/print/:id',
          name: 'page-manual-print',
          component: () => import('@/views/apps/invoice/PrintPage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'پرینت فاکتور', active: true }
            ],
            rule: 'User'
          }
        },
        {
          path: '/apps/manual/ManualPrint/:id',
          name: 'page-manual-print-monthly',
          component: () => import('@/views/apps/invoice/ManualPrint.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'پرینت فاکتور', active: true }
            ],
            rule: 'User'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'User'
          }
        },

        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/pages/payment',
          name: 'payment',
          component: () => import('@/views/pages/Payment.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'User'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'User'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, _, next) => {
  store.commit('auth/IS_AUTH')

  // console.log(to.path)
  if (!(['/pages/login', '/pages/register', '/pages/verifyaccount', '/pages/forgot-password', '/'].includes(to.path))) {
    store.commit('SET_NEXT_DIR', to.path)
    store.dispatch('auth/fetchUserData')
  }
  if ((store.state.auth.isUserLoggedIn.isAuthenticated)) {
    // console.log('login first')
    return next()
  } else {

    return next()
  }

})

// router.beforeEach((to, from, next) => {
//   store.commit('auth/IS_AUTH')
//   if (
//     to.path === 'pages/login' ||
//     to.path === '/pages/register' ||
//     to.path === '/pages/reset-password' ||
//     to.path === '/pages/forgot-password' ||
//     to.path === '/pages/verifyaccount' ||
//     to.path === '/callback'
//   ) {
//     next()
//   } else {
//     localStorage.setItem('dir', to.path)
//     if (!store.getters['auth/userAuthState']) {
//       next({name: 'page-login'})
//     }
//     return next()
//   }

// })
//   // if (
//   //     to.path === "/pages/login" ||
//   //     to.path === "/pages/forgot-password" ||
//   //     to.path === "/pages/error-404" ||
//   //     to.path === "/pages/error-500" ||
//   //     to.path === "/pages/register" ||
//   //     to.path === "/callback" ||
//   //     to.path === "/pages/comingsoon" ||
//   //     (auth.isAuthenticated() || firebaseCurrentUser)
//   // ) {
//   //     return next();
//   // }

//   // If auth required, check login. If login fails redirect to login page

//   return next()
//   // Specify the current path as the customState parameter, meaning it  // will be returned to the application after auth
//   // auth.login({ target: to.path });

// })

export default router
