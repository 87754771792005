export default {
  // Endpoints
  otpLoginEndpoint: '/user/otp/login',
  otpLoginVerifyEndpoint: '/user/otp/login/verify',
  loginEndpoint: '/user/login',
  registerEndpoint: '/user/register',
  refreshEndpoint: '/user/refresh/token',
  logoutEndpoint: '/user/logout',
  userInfoEndpoint : '/user/info',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
