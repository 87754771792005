
import axios from '@/axios'

export default {
  getNotificationList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/notifications').then(response => {
        // console.log('response', response)
        commit('UPDATE_NOTIFICATION_LIST', response.data.params)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}